<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-01-24 08:54:29
 * @LastEditors: ------
 * @LastEditTime: 2022-02-08 14:50:34
-->
<template>
  <div class="not_container">
    <div class="notFont">
      <span>404</span>
      <span>未找到此页面</span>
      <br />
      <van-button to="/" plain hairline round type="info">返回首页</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.not_container {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: rgb(233, 232, 232);

  .notFont {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 50vh;
    color: rgb(145, 144, 144);
  }
}
</style>
